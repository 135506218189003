$( document ).ready(function() {

    $('.banner').slick({
        fade: true,
        autoplay:true,
        dots: true,
        infinite: true,
        speed: 300,
        arrows:false
    });

    $('.galleria_socialin').slick({
        //fade: true,
        autoplay:true,
        dots: false,
        infinite: true,
        speed: 300,
        arrows:true
    });

    $(".ocultar").click(function() {
      
        var data_status = $(this).parent().children(".overflow").attr('data-status');
        //alert(data_status);
        if(data_status == '0'){
            //alert("abrir");
            $(this).parent().children(".overflow")
            .animate({
                height: '100%'
            },500).attr('data-status','1');
            $(this).children('.btn-leiamais').html('Ocultar');
        }else{
            $(this).parent().children(".overflow")
            .animate({
                height: '39px'
            },500).attr('data-status','0');
            $(this).children('.btn-leiamais').html('Abrir');
        }

       
        return false;
    });

    $(".nav_mobile").click(function() {
        $('.shadow, .close, .menu nav').fadeIn(500);
        return false;
    });
     $(".close").click(function() {
        $('.shadow, .close, .menu nav').fadeOut(500);
        return false;
    });


    $(document.body).on('click','.name', function(e) {

        slide = $(this).attr("data-count");
        id = $(this).attr("data-id");

        $.ajax({
            type: "GET",
            data: "id="+id,
            url: "buscaimagem",
            success: function(result){
                $(".foto_enterprise").attr('src', result);
                console.log(result);
            }
        });

        $("#"+slide).slideToggle();

        e.preventDefault();
    });


});